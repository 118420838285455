import { bindToggles } from "@/components/organisms/toggle/index";
import { bindCarousels } from "@/components/organisms/carousel/index";
// import { bindScrollTo } from "@/components/organisms/scroll-to/index";
import { bindDonateForm } from "@/components/organisms/donation-form/index";
import { bindShowAt } from "@/components/organisms/show-at/index";
import { bindPostcodeField } from "@/components/organisms/postcode-field/index";
import { bindTruncateNav } from "@/components/organisms/truncate-nav/index";
import { bindRemoveElement } from "@/components/organisms/remove-element/index";
import { bindEmailMP } from "@/components/organisms/email-your-mp/index";

bindToggles();
bindCarousels();
// bindScrollTo();
bindDonateForm();
bindShowAt();
bindPostcodeField();
bindTruncateNav();
bindRemoveElement();
bindEmailMP();

export default {
  // myModule,
};
