function RemoveElement(el) {

  const targetEls = document.querySelectorAll(el.dataset.targetSelector);

  el.addEventListener("click", function(e) {
    e.preventDefault();

    targetEls.forEach((item) => {
      item.remove();
    });
  });

}

export function bindRemoveElement() {
  return [...document.querySelectorAll("[data-remove-element]")].map((el) => RemoveElement(el));
}
