let currentIndex = 0;
let items = [];
let itemsCount = 0;
let current = [];

function carouselGo() {
  items.forEach((item) => {
    item.classList.add("hidden");
  });
  items[currentIndex].classList.remove("hidden");
  current.innerHTML = currentIndex + 1;
}

function carouselNext() {
  currentIndex += 1;
  if (currentIndex === itemsCount) {
    currentIndex = 0;
  }
  carouselGo();
}

function carouselPrev() {
  currentIndex -= 1;
  if (currentIndex < 0) {
    currentIndex = itemsCount - 1;
  }
  carouselGo();
}

function Carousel(el) {
  items = Array.from(el.querySelectorAll("[data-carousel-item]"));
  itemsCount = items.length;
  const nextButton = el.querySelector("[data-carousel-next]");
  const prevButton = el.querySelector("[data-carousel-prev]");
  const controls = el.querySelector("[data-carousel-controls]");
  const status = el.querySelector("[data-carousel-status]");
  current = el.querySelector("[data-carousel-current]");

  console.log(status);

  items.forEach((item) => {
    item.addEventListener("click", carouselNext);
  });

  controls.classList.remove("hidden");
  status.classList.remove("hidden");

  nextButton.addEventListener("click", carouselNext);
  prevButton.addEventListener("click", carouselPrev);

  carouselGo();
}

export function bindCarousels() {
  return [...document.querySelectorAll("[data-carousel]")].map((el) => Carousel(el));
}
