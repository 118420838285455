let truncateAt = 5;
let toggleClassList = ["foo", "bar"];
const toggleText = "More +";
const toggleTextActive = "Less -";
let hiddenItems = [];

function TruncateNav(el) {
  truncateAt = el.dataset.truncateAt ?? truncateAt;

  // Get all nav items
  const navItems = Array.from(el.children);

  // Get and hide nav items after truncateAt, if available
  if (navItems.length > truncateAt) {
    hiddenItems = navItems.slice(truncateAt);

    hiddenItems.forEach((item) => {
      item.classList.add("hidden");
    });

    // Get toggle classes
    toggleClassList = el.dataset.truncateToggleClass.split(" ") ?? toggleClassList;

    // Add toggle to show/hide items
    const toggleEl = document.createElement("button");
    toggleEl.classList.add(...toggleClassList);
    toggleEl.innerHTML = toggleText;
    el.appendChild(toggleEl);

    // Bind toggle click event
    toggleEl.addEventListener("click", function() {
      hiddenItems.forEach((item) => {
        item.classList.toggle("hidden");
      });

      // Change toggle text
      toggleEl.innerHTML = toggleEl.innerHTML === toggleText ? toggleTextActive : toggleText;
    });
  }



}

export function bindTruncateNav() {
  return [...document.querySelectorAll("[data-truncate-nav]")].map((el) => TruncateNav(el));
}
