let scrollPosition = window.scrollY;
let showPositionPx = 0;
let visible = true;
let showClass = "active";
let hideClass = "inactive";

function showElement(el) {
  if (!visible) {
    el.classList.add(showClass);
    el.classList.remove(hideClass);
    visible = true;
  }
}

function hideElement(el) {
  if (visible) {
    el.classList.remove(showClass);
    el.classList.add(hideClass);
    visible = false;
  }
}

function checkPosition(el) {
  scrollPosition = window.scrollY;
  if (scrollPosition >= showPositionPx) {
    showElement(el);
  } else {
    hideElement(el);
  }
}

function ShowAt(el) {
  const showPosition = el.getAttribute("data-show-at");
  showClass = el.getAttribute("data-show-class") || showClass;
  hideClass = el.getAttribute("data-hide-class") || hideClass;

  if (showPosition.includes("vh")) {
    const p = parseInt(showPosition.replace("vh", ""), 10) / 100;
    showPositionPx = window.innerHeight * p;
  }

  checkPosition(el);

  document.addEventListener("scroll", function() {
    checkPosition(el);
  });
}

export function bindShowAt() {
  return [...document.querySelectorAll("[data-show-at]")].map((el) => ShowAt(el));
}
