const donateButton = document.querySelector("[data-donate-button]");
const reqCheckboxes = Array.from(document.querySelectorAll("[data-donate-required-checkbox]"));


function enableSubmit() {
  donateButton.disabled = false;
  donateButton.classList.remove("disabled");
}

function disableSubmit() {
  donateButton.disabled = true;
  donateButton.classList.add("disabled");
}

function checkCheckBoxes() {

  let valid = true;

  reqCheckboxes.forEach((item) => {
    if (!item.checked) {
      valid = false;
    }
  });
  if (valid) {
    enableSubmit();
  } else {
    disableSubmit();
  }
}

function DonateForm() {

  // Disable submit button
  disableSubmit();

  // Only when all reqCheckboxes are ticked, endable submit button
  reqCheckboxes.forEach((item) => {
    item.addEventListener("change", function() {
      checkCheckBoxes();
    });
  });

  
}

export function bindDonateForm() {
  return [...document.querySelectorAll("[data-donate-form]")].map(() => DonateForm());
}
