let postcode = '';
let endChars = '';
let startChars = '';

function PostcodeField(el) {
  el.addEventListener("blur", function() {
    postcode = this.value.replace(/\s/g, '');

    if (postcode !== '') {
      // capitalise
      postcode = postcode.toUpperCase();

      // add space before final 3 chars
      if (postcode.length > 5) {
        endChars = postcode.slice(-3);
        startChars = postcode.slice(0, postcode.length - 3);
        postcode = `${startChars} ${endChars}`;
      }
    }

    this.value = postcode;

  });
}

export function bindPostcodeField() {
  return [...document.querySelectorAll("[data-postcode-field]")].map((el) => PostcodeField(el));
}
