function toggle(el, targetEl, fixedEl) {
  el.classList.toggle("active");
  targetEl.classList.toggle("hidden");
  if (fixedEl) {
    fixedEl.classList.toggle("hidden");
    document.getElementsByTagName("html")[0].classList.toggle("is-fixed");
  }
}

function hide(el, targetEl, fixedEl) {
  el.classList.remove("active");
  targetEl.classList.add("hidden");
  if (fixedEl) {
    fixedEl.classList.add("hidden");
    document.body.classList.remove("overflow-hidden");
  }
}

function Toggler(el) {
  const targetId = el.getAttribute("data-target").replace("#", "");
  const targetEl = document.getElementById(targetId);
  const callbackFn = window[el.dataset.toggleCallback];
  const toggleGroupName = el.dataset.toggleGroup;
  const toggleGroup = document.querySelectorAll(`[data-toggle-group="${toggleGroupName}"]`);
  const toggleFixed = el.dataset.toggleFixed || false;
  const fixedEl = document.getElementById(toggleFixed) || false;

  // Only hide if not overriden
  if (targetEl.dataset.toggleShow !== "") {
    hide(el, targetEl, fixedEl);
  }

  el.addEventListener("click", function(e) {
    if (el.tagName === "A") {
      e.preventDefault();
    }

    // Hide other members of toggle group
    toggleGroup.forEach((item) => {
      if (item !== this) {
        const gTargetId = item.getAttribute("data-target").replace("#", "");
        const gTargetEl = document.getElementById(gTargetId);

        hide(item, gTargetEl, fixedEl);
      }
    });

    toggle(this, targetEl, fixedEl);

    // Overlay

    // Callback
    if (typeof callbackFn === "function") {
      callbackFn();
    }
  });
}

export function bindToggles() {
  return [...document.querySelectorAll("[data-toggle]")].map((el) => Toggler(el));
}
